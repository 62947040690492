<template>
    <el-config-provider :locale="locale">
        <router-view v-if="isRouterAlive" />
    </el-config-provider>
</template>
<script>
import { onMounted, onBeforeMount, provide, nextTick, ref } from "vue";

import { ElConfigProvider } from "element-plus";
import store from "@/store/index";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
const { body } = document;
// h5适配
const WIDTH = 992; // refer to Bootstrap's responsive design
export default {
    components: {
        [ElConfigProvider.name]: ElConfigProvider,
    },
    setup() {
        let isRouterAlive = ref(true);
        let isMobile = null;
        const getMobile = () => {
            const rect = body.getBoundingClientRect();
            isMobile = rect.width - 1 < WIDTH;
            if (isMobile) {
                store.dispatch("app/toggleDevice", "mobile");
            } else {
                store.dispatch("app/toggleDevice", "desktop");
            }
        };
        const resizeHandler = () => {
            if (!document.hidden) {
                const isMobiles = isMobile;
                store.dispatch("app/toggleDevice",isMobiles ? "mobile" : "desktop");

                if (isMobiles) {
                    store.dispatch("app/closeSideBar", {
                        withoutAnimation: true,
                    });
                }
            }
        };
        const reload = () => {
            isRouterAlive.value = false;
            nextTick(() => {
                isRouterAlive.value = true;
            });
        };
        onBeforeMount(() => {
            window.addEventListener("resize", resizeHandler());
        });
        onMounted(() => {
            document.body.style.setProperty("--el-color-primary", "#0eb738");
            document.body.style.setProperty(
                "--el-color-primary-light-2",
                "#0eb738"
            );
            document.body.style.setProperty(
                "--el-color-primary-light-3",
                "#66FF8C"
            );
            document.body.style.setProperty(
                "--el-color-primary-light-5",
                "#A0FFB8"
            );
            document.body.style.setProperty(
                "--el-color-primary-light-7",
                "#C6FFD4"
            );
            document.body.style.setProperty(
                "--el-color-primary-light-8",
                "#D9FFE3"
            );
            document.body.style.setProperty(
                "--el-color-primary-light-9",
                "#EBFFF0"
            );
            document.body.style.setProperty(
                "--el-color-primary-dark-2",
                "#0eb738"
            );
            getMobile();
        });
        let locale = zhCn;
        provide("reload", reload);
        return {
            isMobile,
            locale,
            isRouterAlive,
            reload,
        };
    },
};
</script>

<style lang="less">
</style>
