/**
 *
 * 数据字典工具类
 */
import store from '../../store'


export const DICT_TYPE = {
	COMMON_STATUS: 'common_status',
	// 商家相册
	MERCHANT_PHOTO: 'merchant_photo',
	// 商家相册
	TEMPLATE_TYPE: 'template_type',
	// 商家故事类型
	MERCHANT_STORY_TYPE: 'merchant_story_type',
	// 商家服务类型
	MERCHANT_SERVICE: 'merchant_service',
	// 审核类型
	APPROVAL_TYPE: 'approval_type',
	// 特色服务
	SPECIAL_SERVICE: 'special_service',
	AD_POSITION: 'ad_position',
	AD_TYPE: 'ad_type',
	TIME_UNIT: 'time_unit',
	APPLY_TYPE: 'apply_type',
	SYSTEM_ROLE_TYPE: 'system_role_type',
	SYSTEM_USER_SEX: 'system_user_sex',
	SYSTEM_MENU_TYPE: 'system_menu_type',
	SYSTEM_DATA_SCOPE: 'system_data_scope'
}
/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas(dictType) {
	return store.getters.dictDatas[dictType] || []
}

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @param values 数组、单个元素
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas2(dictType, values) {
	if (values === undefined) {
		return [];
	}
	// 如果是单个元素，则转换成数组
	if (!Array.isArray(values)) {
		values = [this.value];
	}
	// 获得字典数据
	const results = [];
	for (const value of values) {
		const dict = getDictData(dictType, value);
		if (dict) {
			results.push(dict);
		}
	}
	return results;
}


export function getDictData(dictType, value) {
	// 获取 dictType 对应的数据字典数组
	const dictDatas = getDictDatas(dictType)
	if (!dictDatas || dictDatas.length === 0) {
		return ''
	}
	// 获取 value 对应的展示名
	value = value + '' // 强制转换成字符串，因为 DictData 小类数值，是字符串
	for (const dictData of dictDatas) {
		if (dictData.value === value) {
			return dictData;
		}
	}
	return undefined
}

export function getDictDataLabel(dictType, value) {
	const dict = getDictData(dictType, value);
	return dict ? dict.label : '';
}

export class getDictDataL {
}
